import { React, useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./Contact.css";
const Contact = ({ language, theme }) => {
  const form = useRef();
  const MySwal = withReactContent(Swal);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(form.current);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          MySwal.fire({
            icon: "success",
            title: language ? (
              <strong>Your message was sent successfully!</strong>
            ) : (
              <strong>Ihre Nachricht wurde erfolgreich gesendet!</strong>
            ),
          });
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: language ? (
              <strong>Your message could not be sent!</strong>
            ) : (
              <strong>Ihre Nachricht konnte nicht gesendet werden!</strong>
            ),
          });
        }
      );
  };

  return (
    <section id="contact">
      <div className="contact">
        <fieldset
          className="contactField"
          data-aos="zoom-in-up"
          data-aos-duration="1500"
        >
          <legend>
            <h2 className="contactLegend unselectable">
              {language ? "Contact Me" : "Schreiben Sie mir"}
            </h2>
          </legend>
          <form ref={form} onSubmit={handleSubmit} className="contactForm">
            <div className="contactDiv">
              <label className="contactLabel unselectable" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="user_name"
                className="contactInput"
                placeholder={language ? "Your name" : "Ihr Name"}
                required
                title={language ? "eg. 'John Doe'" : `z. B. "Max Mustermann"`}
              />

              <label className="contactLabel unselectable" htmlFor="email">
                eMail
              </label>
              <input
                type="email"
                id="email"
                name="user_email"
                className="contactInput"
                placeholder="eMail"
                required
              />

              <label className="contactLabel unselectable" htmlFor="message">
                {language ? "Message" : "Nachricht"}
              </label>
              <textarea
                name="message"
                id="message"
                className="contactInput"
                placeholder={language ? "Message" : "Nachricht"}
                required
                rows="12"
              ></textarea>
            </div>
            <button className="contactButton">
              {language ? "Submit" : "Abschicken"}
            </button>
          </form>
        </fieldset>
      </div>
    </section>
  );
};

export default Contact;

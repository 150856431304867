import { useState, useEffect } from "react";
import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./stuff/Navbar";
import Footer from "./stuff/Footer";
import Home from "./stuff/Home";
import Imprint from "./stuff/Imprint";
import { ReactComponent as Scroll } from "./img/scroll.svg";
import { HashLink } from "react-router-hash-link";

function App() {
  const [showButton, setShowButton] = useState(false);
  const [language, setLanguage] = useState(true);
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
  );

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 150 ? setShowButton(true) : setShowButton(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <div className="app" id="top" data-theme={theme}>
      <div className="backgrounds" />
      {/* <div className="overlay" /> */}
      <Navbar
        setLanguage={setLanguage}
        language={language}
        switchTheme={switchTheme}
        theme={theme}
      />
      {showButton && (
        <div>
          <HashLink
            to="/#"
            title={
              language
                ? `Click to go to top of the page`
                : "Klicke um zum Anfang der Seite zu springen"
            }
          >
            <Scroll fill="var(--text-secondary)" className="scrollToTop" />
          </HashLink>
        </div>
      )}
      <Routes>
        <Route
          path="/imprint"
          element={<Imprint language={language} theme={theme} />}
        />
        <Route path="/" element={<Home language={language} theme={theme} />} />
      </Routes>
      <Footer language={language} />
    </div>
  );
}

export default App;

import React from "react";
import "./Imprint.css";
// import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useEffect } from "react";
// import ReactGA from "react-ga4";
// const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
// ReactGA.initialize(TRACKING_ID);

const Imprint = ({ language, theme }) => {
  const imprintArray = [
    "Kanzowstraße 18, 10439 Berlin",
    "Telefon: +49 (0)176 / 789 33 4 55",
    "mailto:impressum@timonschell.com?subject=impressum",
  ];

  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: "timonschell.com/imprint" });
  // }, []);

  return (
    <div className="imprint">
      {/* <Helmet>
        <title>Imprint</title>
        <meta name="description" content="Timon Schell - Portfolio Imprint." />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://timonschell.com/imprint" />
      </Helmet> */}
      <fieldset
        className="imprintField"
        data-aos="zoom-in-up"
        data-aos-duration="1500"
      >
        <legend>
          <h1 className="imprintLegend unselectable">
            {language ? "Imprint" : "Impressum"}
          </h1>
        </legend>

        <div className="imprintOuter">
          <div className="imprintDiv unselectable">
            <h3>Timon Schell</h3>

            <p>{language ? "Web Developer" : "Webentwickler"}</p>
            <p>{imprintArray[0]}</p>
            <p>{imprintArray[1]}</p>
            <a href={imprintArray[2]}>
              {language ? "Send email" : "Email senden"}
            </a>

            <p>
              {language
                ? "\nResponsible person within the meaning of § 18 para. 2 MStV:\n\n"
                : "\nVerantwortlicher i.S.d. § 18 Abs. 2 MStV:\n\n"}
            </p>

            <p>Timon Schell</p>
            <p>{imprintArray[0]}</p>
          </div>
        </div>
      </fieldset>
      <Link to="/" className="imprintButton">
        {language ? "Back" : "Zurück"}
      </Link>
    </div>
  );
};

export default Imprint;

import React from "react";
import "./About.css";

const About = ({ theme, language }) => {
  return (
    <section id="about">
      <div className="about">
        <div
          data-aos="fade-down"
          data-aos-duration="2000"
          className="abouthr unselectable"
        >
          <h2>About me</h2>
          {theme === "dark" ? (
            <div className="hr hrwhite" />
          ) : (
            <div className="hr hrblack" />
          )}
        </div>
        <div className="margintop">
          <div className="unselectable timpar">
            {language ? (
              <p data-aos="fade-left" data-aos-duration="1500">
                Computer Science and Sports are my two biggest passions. After
                working as a Project Manager and social media analyst, where I
                got in contact with Web Development, I felt a need to dive more
                into IT topics. Therefore, I started to study and work for some
                years as an IT teacher and released a couple of smaller
                projects. In between, I took charge of the IT components of a
                Covid Test station. Consequently, I visited a four-month
                Full-Stack Bootcamp, which was really enlightening given my
                previous knowledge of IT topics. I have strong people skills, am
                good at public speaking and remain calm under pressure. Due to
                my experiences as a Project Manager, I know how to work in a
                team and how to get the best out of its members. I am a big fan
                of efficient problem-solving and I tend to face obstacles head
                on in a goal-oriented manner. Flexibility and quick thinking
                have always been a one of my strong suits.
              </p>
            ) : (
              <p data-aos="fade-left" data-aos-duration="1500">
                Informatik und Sport sind meine beiden größten Leidenschaften.
                Nach meiner Arbeit als Projektmanager und Social Media Analyst,
                wo ich mit Web-Entwicklung in Berührung kam, verspürte ich das
                Bedürfnis, mehr in IT-Themen einzutauchen. Daher begann ich in
                diese Richtung zu studieren und arbeitete einige Jahre als
                IT-Lehrer, während ich kleinere Projekte bearbeitete. Unter
                anderem war ich für die IT einer Covid-Teststation
                verantwortlich. Anschließend besuchte ich ein
                viermonatiges Full-Stack Bootcamp, was angesichts meiner
                Vorkenntnisse in IT-Themen sehr gewinnbringend für mich war.
                Aufgrund meiner beruflichen Erfahrungen, verfüge ich über eine
                ausgeprägte Sozialkompetenz, bin gut im Public Speaking und
                bleibe auch unter Druck gelassen. Aufgrund meiner Erfahrungen
                als Projektmanager weiß ich, wie man in einem Team arbeitet und
                wie man das Beste aus seinen Mitgliedern herausholt. Ich bin ein
                großer Fan von effizienten Problemlösungen und überwinde
                Hindernisse zielgerichtet. Flexibilität und schnelles Denken
                gehörten schon immer zu meinen Stärken.
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

import React from "react";
import { SocialIcon } from "react-social-icons";

const Links = () => {
  return (
    <div className="flex links" data-aos="fade-up" data-aos-duration="1500">
      <p>Links:</p>
      <div className="separator" />
      <SocialIcon
        style={{ height: 30, width: 30 }}
        url="https://www.linkedin.com/in/timonschell/"
        bgColor="var(--text-secondary)"
        target="_blank"
        className="socialicon"
        title="LinkedIn"
      />
      <div className="separator" />
      <SocialIcon
        network="xing"
        style={{ height: 30, width: 30, Left: "0.5em" }}
        url="https://www.xing.com/profile/Timon_Schell"
        bgColor="var(--text-secondary)"
        className="socialicon"
        title="Xing"
      />
      <div className="separator" />
      <SocialIcon
        style={{ height: 30, width: 30 }}
        url="https://github.com/Apollosport"
        bgColor="var(--text-secondary)"
        target="_blank"
        className="socialicon"
        title="GitHub"
      />

      <div className="separator" />
      <SocialIcon
        network="email"
        style={{ height: 30, width: 30, Left: "0.5em" }}
        url="/#contact"
        bgColor="var(--text-secondary)"
        className="socialicon"
        title="Mail"
      />
    </div>
  );
};

export default Links;

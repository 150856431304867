import { React } from "react";
import "./Top.css";
import Links from "./Links";
import profile from "../img/profile.jpg";

const Top = ({ language, theme }) => {
  return (
    <section id="toptwo">
      <div className="flex topdiv">
        <div className="picdiv">
          <img
            src={profile}
            width="500"
            height="485"
            className="pic"
            data-aos="fade-right"
            data-aos-duration="1500"
            alt="Profil pic"
            title="Timon Schell"
          />
        </div>
        <div className="detaildiv">
          <div className="unselectable moep">
            <div
              className="outerShimmer"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <h1 className="shimmer">Timon Schell</h1>
            </div>
            {language ? (
              <div
                className="details"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <p>Base: Berlin</p>
                <p>
                  Professions: Web&nbsp;Developer, Project&nbsp;Manager, Teacher
                </p>
                <p>Languages: German, English</p>
                <Links />
              </div>
            ) : (
              <div
                className="details"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <p>Wohnort: Berlin</p>
                <p>Berufe: Webentwickler, Projektmanager, Lehrer</p>
                <p>Sprachen: Deutsch, Englisch</p>
                <Links />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Top;

import React from "react";
import JavaScript from "../img/tech/JavaScript.png";
import Typescript from "../img/tech/Typescript.png";
import ReactLogo from "../img/tech/react.png";
import NodeJs from "../img/tech/Node.js.png";
import HTML5 from "../img/tech/HTML5.png";
import CSSLogo from "../img/tech/CSS3.png";
import PostgreSQL from "../img/tech/postgresql.png";
import Mongodb from "../img/tech/mongodb.png";
import JavaLogo from "../img/tech/java.png";
import PythonLogo from "../img/tech/Python.png";
import DjangoLogo from "../img/tech/django.png"
import CKAD from "../img/tech/CKAD.png";
import Kubernetes from "../img/tech/Kubernetes.png";
import Docker from "../img/tech/Docker.png";
import Tilt from "react-parallax-tilt";

import "./Skills.css";

const Skills = ({ language, theme }) => {
  const tiltsettings = {
    tiltMaxAngleX: "30",
    tiltMaxAngleY: "30",
    perspective: "1000",
    transitionSpeed: "1000",
    scale: "1.2",
    // gyroscope: "true",
  };
  return (
    <section id="skills">
      <div className="skills">
        <div
          data-aos="fade-down"
          data-aos-duration="2000"
          className="skillshr unselectable"
        >
          <h2>Tech Stack</h2>
          {theme === "dark" ? (
            <div className="hr hrwhite" />
          ) : (
            <div className="hr hrblack" />
          )}
        </div>

        <div className="unselectable skillstable">
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={JavaScript}
              className="skillIcons"
              alt="JavaScript Logo"
              title="JavaScript"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={Typescript}
              className="skillIcons"
              alt="Typescript Logo"
              title="Typescript"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={ReactLogo}
              className="skillIcons"
              alt="React.JS Logo"
              title="React.JS"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={NodeJs}
              className="skillIcons invert"
              alt="NodeJS Logo"
              title="NodeJS"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={HTML5}
              className="skillIcons"
              alt="HTML5 Logo"
              title="HTML5"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={CSSLogo}
              className="skillIcons"
              alt="CSS3 Logo"
              title="CSS3"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={Mongodb}
              className="skillIcons"
              alt="MongoDB Logo"
              title="MongoDB"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={PostgreSQL}
              className="skillIcons"
              alt="PostgreSQL Logo"
              title="PostgreSQL"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={JavaLogo}
              className="skillIcons"
              alt="Java Logo"
              title="Java"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={PythonLogo}
              className="skillIcons"
              alt="Python Logo"
              title="Python"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={DjangoLogo}
              className="skillIcons"
              alt="Django Logo"
              title="Django"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={Docker}
              className="skillIcons"
              alt="Docker Logo"
              title="Docker"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={Kubernetes}
              className="skillIcons"
              alt="Kubernetes Logo"
              title="Kubernetes"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
          <Tilt {...tiltsettings} className="tilter">
            <img
              src={CKAD}
              className="skillIcons"
              alt="CKAD Logo"
              title="CKAD"
              data-aos="flip-left"
              data-aos-duration="1500"
              loading="lazy"
            />
          </Tilt>
        </div>
      </div>
    </section>
  );
};

export default Skills;

// import { useEffect } from "react";
import React from "react";
import Skills from "./Skills";
import Projects from "./Projects";
import About from "./About";
import Contact from "./Contact";
import Top from "./Top";
import BottomArrow from "./BottomArrow";
import "aos/dist/aos.css";
import "./Home.css";
// import { Helmet } from "react-helmet-async";

// import ReactGA from "react-ga4";
// const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
// ReactGA.initialize(TRACKING_ID);

const Home = ({ theme, language }) => {
  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: "timonschell.com/" });
  // }, []);

  return (
    <div className="home">
      {/* <Helmet>
        <title>Timon Schell - Full Stack Developer</title>
        <meta
          name="description"
          content="Portfolio of a Full Stack Developer giving people the opportunity to connect with me."
        />
        <link rel="canonical" href="https://timonschell.com/" />

        <meta property="og:title" content="Timon Schell - Portfolio" />
        <meta property="og:type" content="Portfolio" />
        <meta
          property="og:description"
          content="Portfolio of a Full Stack Developer giving people the opportunity to connect with me."
        />
        <meta
          property="og:image"
          content="http://timonschell.com/static/media/profile.5e5e3150587d688276c9.jpg"
        />
        <meta property="og:url" content="http://timonschell.com" />
      </Helmet> */}
      <Top theme={theme} language={language} />
      <About theme={theme} language={language} />
      <BottomArrow theme={theme} />
      <Projects language={language} theme={theme} />
      <Skills language={language} theme={theme} />
      <BottomArrow theme={theme} />
      <Contact language={language} theme={theme} />
    </div>
  );
};

export default Home;

import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = ({ language }) => {
  return (
    <>
      <footer className="footer unselectable">
        <p>© Timon Schell</p>
        <Link to="/imprint" className="imprintLink underline">
          {language ? "Imprint" : "Impressum"}
        </Link>
      </footer>
    </>
  );
};

export default Footer;

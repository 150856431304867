import React from "react";
import "./Projects.css";
import askalocal from "../img/askalocal2.jpg";
import pokemon from "../img/pokemon.jpg";
import superSoaker from "../img/super-soaker.de.jpg"
import Tilt from "react-parallax-tilt";

const Projects = ({ theme, language }) => {
  const tiltsettings = {
    tiltMaxAngleX: "30",
    tiltMaxAngleY: "30",
    perspective: "1000",
    transitionSpeed: "1000",
    scale: "1.2",
    // gyroscope: "true",
  };
  return (
    <section id="projects">
      <div
        className="projects"
        data-aos="flip-right"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <div
          data-aos="fade-down"
          data-aos-duration="2000"
          className="projectshr unselectable"
        >
          <h2>{language ? "Projects" : "Projekte"}</h2>
          {theme === "dark" ? (
            <div className="hr hrwhite" />
          ) : (
            <div className="hr hrblack" />
          )}
        </div>
        <div className="projectList">
        <a
            href="https://toyguns.netlify.app/"
            target="_blank"
            rel="noreferrer"
            className="alink"
          >
            <Tilt
              {...tiltsettings}
              className="projectCard"
              href="https://toyguns.netlify.app/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={superSoaker}
                alt="Toyguns Shop"
                className="projectPic"
                loading="lazy"
              />
              <div className="projectText">
                <h2>Toyguns</h2>
                <p className="projectP">
                  {language
                    ? "A private project with a big focus on UX, which helps toy gun enthusiasts find the right water gun or Nerf blaster."
                    : "Ein privates Project mit großem Fokus auf UX, welche Enthusiasten von Spielzeugpistolen hilft, die richtige Wasserpistole oder Nerf-Blaster zu finden."}
                </p>
              </div>
            </Tilt>
          </a>
          <a
            href="https://askalocal.netlify.app/"
            target="_blank"
            rel="noreferrer"
            className="alink"
          >
            <Tilt
              {...tiltsettings}
              className="projectCard"
              href="https://askalocal.netlify.app/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={askalocal}
                alt="Ask a Local"
                className="projectPic"
                loading="lazy"
              />
              <div className="projectText">
                <h2>Ask a Local</h2>
                <p className="projectP">
                  {language
                    ? "An exciting project that helps expats connect with locals and use their services wherever language barriers arise."
                    : "Ein spannendes Projekt, das Expats dabei hilft, Kontakte zu Einheimischen zu knüpfen und deren Dienste zu nutzen, wo immer Sprachbarrieren auftreten."}
                </p>
              </div>
            </Tilt>
          </a>
          <a
            href="https://pokemon-fight-app-project.netlify.app/"
            target="_blank"
            rel="noreferrer"
            className="alink"
          >
            <Tilt
              {...tiltsettings}
              className="projectCard"
              href="https://askalocal.netlify.app/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={pokemon}
                alt="Pokemon"
                className="projectPic"
                loading="lazy"
              />
              <div className="projectText">
                <h2>Pokemon Fights!</h2>
                <p className="projectP">
                  {language
                    ? "Cool little Pokemon game with high scores for a short pastime."
                    : "Cooles kleines Pokemon-Spiel mit Highscores für einen kurzen Zeitvertreib."}
                </p>
              </div>
            </Tilt>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Projects;

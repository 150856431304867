import { useState } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import icon from "../img/icon.png";
import { ReactComponent as Hamburger } from "../img/hamburger.svg";
import "./Navbar.css";
import en from "../img/en.svg";
import de from "../img/de.svg";

const Navbar = ({ switchTheme, language, setLanguage, theme }) => {
  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;
  const [showNav, setShowNav] = useState(false);

  const handleShowNav = () => {
    setShowNav((prev) => !prev);
    console.log(showNav);
  };

  return (
    <nav
      className="outerNav"
      // data-aos-duration="1000" data-aos="fade-up"
    >
      <HashLink
        to="/#"
        className={
          isActive("#top") ? "active logo navSwitches" : "logo navSwitches"
        }
        state="top"
        title={
          language
            ? `Click to go to top of the page`
            : "Klicke um zum Anfang der Seite zu springen"
        }
      >
        <img src={icon} alt="PC" className="imgNavbar" />
      </HashLink>
      <div className="" onClick={handleShowNav}>
        <Hamburger className="hamburgersvg" />
      </div>
      <div className="placeholder"></div>
      <div className={`nav-elements  ${showNav && "active"}`}>
        <HashLink
          to="/#about"
          state="about"
          className={
            isActive("#about")
              ? "active navLinks underline"
              : "navLinks underline"
          }
        >
          {language ? "About" : "Über"}
        </HashLink>
        <HashLink
          to="/#projects"
          state="projects"
          className={
            isActive("#projects")
              ? "active navLinks underline"
              : "navLinks underline"
          }
        >
          {language ? "Projects" : "Projekte"}
        </HashLink>
        <HashLink
          to="/#skills"
          state="skills"
          className={
            isActive("#skills")
              ? "active navLinks underline"
              : "navLinks underline"
          }
        >
          Skills
        </HashLink>
        <HashLink
          to="/#contact"
          state="contact"
          className={
            isActive("#contact")
              ? "active navLinks underline"
              : "navLinks underline"
          }
        >
          {language ? "Contact" : "Kontakt"}
        </HashLink>
        <div
          title={language ? "Change language" : "Sprache ändern"}
          className="navSwitches"
        >
          <img
            src={language ? de : en}
            onClick={() => setLanguage((prev) => !prev)}
            alt="Language"
            className="lang"
          />
          <div
            id="toggledark"
            title={
              language
                ? "(De)activate dark mode"
                : "Dunklen Modus (de)aktivieren"
            }
          >
            <label className="switch">
              <input id="dark" type="checkbox" />
              <span onClick={() => switchTheme()} className="slider"></span>
            </label>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
